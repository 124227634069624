<template>
  <el-card class="main" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form style="margin-left: 0px" :model="editform" label-width="100px" label-position="right" :rules="rules" ref="inserform">

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="登录账号：" prop="username">
              <el-input v-model.trim="editform.username" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企微账号：" prop="qwName">
              <el-input v-model.trim="editform.qwName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="修改密码：" prop="pwd">
              <el-input v-model.trim="pwd" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码：" prop="password">
              <el-input v-model.trim="editform.password" type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>




        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="成员姓名：" prop="realname">
              <el-input v-model.trim="editform.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码：" prop="mobile">
              <el-input v-model.trim="editform.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="邮箱地址：" prop="email">
              <el-input v-model.trim="editform.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="成员角色：" prop="roleId">
              <el-select v-model.trim="editform.roleId" placeholder="请选择成员角色">
                <el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="departmentId">
              <el-select v-model="editform.departmentId" placeholder="请选择部门">
                <el-option v-for="item in departmentlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级名称：" prop="parentId">
              <el-select v-model="editform.parentId" placeholder="请选择部门">
                <el-option v-for="(item, index) in sjuser" :key="item" :label="item" :value="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>




        <el-form-item label="备注信息： " prop="remark">
          <el-input type="textarea" v-model="editform.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/user/list"><el-button>取 消</el-button></router-link>
        <el-button type="primary" style="margin-left: 50px" @click="addedituser('inserform')">提 交</el-button>
      </div>
    </div>
  </el-card>
</template>
<script>
import { userSuperiorAll, userDetail, userEdit } from "@/api/user";
import { roleList } from "@/api/role";
import { departmentList } from "@/api/department";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      editform: {
        password: "",
      },
      pwd: null,
      rules: {
        password: [
          { validator: validatePass2, trigger: "blur" },
          { min: 6, message: "长度不可低于6位", trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入成员名称", trigger: "blur" },
          {message: "不能用特殊符号、表情、空格", trigger: "blur",},
        ],
        mobile: [
          { required: true, message: "请填写正确手机号", trigger: "blur" },
          {min: 11, max: 11, message: "手机号为11位数字", trigger: "blur",},
        ],
        qwName: [{ required: true, message: "不能为空", trigger: "blur" },],
        roleId: [{ required: true, message: "请选择成员角色", trigger: "blur" },],
        departmentId: [{ required: true, message: "请输入角色名称", trigger: "blur" },],
      },
      rolelist: [],
      departmentlist: [],
      sjuser: [],
    };
  },
  created() {
    this.list();
  },

  methods: {
    list() {
      //获取角色
      roleList().then((res) => {
        this.rolelist = res.data.list;
      });
      //获取部门
      departmentList().then((res) => {
        this.departmentlist = res.data.list;
      });
      //上级(选项)列表
      userSuperiorAll().then((res) => {
        this.sjuser = res.data;
      });
      // 获取用户详情
      let id = this.$route.query;
      userDetail({ id: id.userid }).then((res) => {
        this.editform = res.data;
      });
    },
    //提交
    addedituser(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          userEdit(this.editform).then((data) => {
            if(data && data.status == 10000){
              this.$router.push("/user/list");
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
/deep/ .main{
  height: 100%;
}
</style>
